const Button = ({ children, className, label, onClick, size, ...props }) => (
  <button
    className={`z-10 bg-white hover:bg-gray-400 focus:outline-none p-1 font-bold rounded-full inline-flex items-center shadow-xl transition-colors duration-200 ease-in-out ${className}`}
    onClick={onClick}
    aria-label={label}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className="fill-current text-gray-800"
      {...props}
    >
      {children}
    </svg>
  </button>
);

Button.defaultProps = {
  size: 28
};

export default Button;
