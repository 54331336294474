import { useMapState, useProgram } from 'utils/store';

import Cluster from 'components/Map/Cluster';
import Marker from 'components/Map/Marker';

const Clusters = ({
  latLngToPixel,
  points,
  selected,
  selectedClusterId,
  onClickCluster
}) => {
  const setProgram = useProgram(state => state.setProgram);
  const setCenter = useMapState(state => state.setCenter);

  if (!points) return null;

  // Consider memoizing this, if too sluggish.
  return points.map(({ data, geometry: { coordinates }, id, properties }) => {
    return properties?.cluster ? (
      <Cluster
        key={`marker-${id}`}
        count={properties.point_count}
        highlight={properties.cluster_id === selectedClusterId}
        index={selected?.index}
        pixelOffset={latLngToPixel([coordinates[1], coordinates[0]])}
        onClick={() => {
          setCenter([coordinates[1], coordinates[0]]);
          onClickCluster(properties.cluster_id);
        }}
      />
    ) : (
      <Marker
        key={`marker-${data?.pid}`}
        highlight={data && data.iid === selected?.institution?.id}
        index={data.index}
        name={data.name}
        pixelOffset={latLngToPixel(data.point)}
        selected={data.pid === selected?.pid}
        onClick={() => setProgram(data)}
      />
    );
  });
};

export default Clusters;
