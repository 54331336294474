import { cluster } from 'components/colors';

const classes = [
  'w-5 h-5 border',
  'w-6 h-6 border',
  'w-8 h-8 border-2',
  'w-10 h-10 border-2'
];

const classesFromCount = count =>
  classes[count > 20 ? 3 : count > 15 ? 2 : count > 5 ? 1 : 0];

function ClusterMarker({ count, highlight, index, pixelOffset, ...props }) {
  const colors = (highlight && cluster[index]) || 'bg-gray-600 border-gray-600';
  return (
    <div
      className={`absolute flex flex-col justify-center items-center cursor-pointer rounded-full shadow-lg text-white ${classesFromCount(
        count
      )} ${highlight ? 'bg-opacity-75 z-10' : 'bg-opacity-50 z-0'} ${colors}`}
      style={{
        left: pixelOffset[0],
        top: pixelOffset[1]
      }}
      {...props}
    >
      <span className="text-xs font-medium leading-snug">{count}</span>
    </div>
  );
}

export default ClusterMarker;
