import { useState, useCallback } from 'react';
import cn from 'classnames';

import Icon from 'components/Icon/Marker';
import isTouch from 'utils/istouch';

import Bubble from './Bubble';

const isHoverable = () => !isTouch();

export const translateX = [
  'translateX(0)',
  'translateX(.75em)',
  'translateX(-.75em)',
  'translateX(1.5em)',
  'translateX(-1.5em)'
];

const Marker = ({ highlight, index, name, pixelOffset, selected, onClick }) => {
  const [hover, setHover] = useState(false);

  const translateY = `translateY(${selected ? '-.4em' : 0})`;

  const handleHoverOver = useCallback(() => isHoverable() && setHover(true));
  const handleHoverOut = useCallback(() => isHoverable() && setHover(false));

  // Reset hover state (sometimes clicking a marker doesn't remove ballon).
  React.useEffect(() => setHover(false), [selected]);

  return (
    <div
      className={cn('absolute', {
        'z-20 pointer-events-none': selected,
        'cursor-pointer': !selected,
        'z-30': !selected && hover,
        'z-10': !selected && !hover
      })}
      style={{
        left: pixelOffset[0],
        top: pixelOffset[1],
        transform: `${translateX[index]} ${translateY}`
      }}
      onClick={onClick}
      onMouseOver={handleHoverOver}
      onMouseLeave={handleHoverOut}
      onMouseOut={handleHoverOut}
    >
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 z-30'">
        <Icon
          index={index}
          size={selected ? 24 : 18}
          variant="pin"
          selected={selected}
        />
      </div>
      {hover && !selected && <Bubble index={index} show={hover} text={name} />}
    </div>
  );
};

export default Marker;
