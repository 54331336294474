import { useCallback } from 'react';
import isTouch from 'utils/istouch';

import { useMapState } from 'utils/store';

import { Path as GlobePath } from 'components/Icon/Globe';
import { Path as PinPath } from 'components/Icon/Pin';

import Button from './Button';

const Zoom = ({ selected, onResetZoom }) => {
  const resetCenter = useMapState(state => state.resetCenter);
  const zoomIn = useMapState(state => state.zoomIn);
  const zoomOut = useMapState(state => state.zoomOut);
  const zoomTo = useMapState(state => state.zoomTo);

  const handleZoomTo = useCallback(() => {
    zoomTo({ center: selected.point, zoom: 14 });
  });

  const handleResetZoom = useCallback(() => {
    resetCenter();
    onResetZoom();
  });

  return (
    <>
      <div className="flex flex-col">
        {selected?.point && (
          <Button
            className="mt-1"
            label="Zoom to marker"
            onClick={handleZoomTo}
          >
            <PinPath />
          </Button>
        )}
        <Button className="mt-1" onClick={handleResetZoom} label="Reset zoom">
          <GlobePath />
        </Button>
        {!isTouch() && (
          <>
            <Button className="mt-1" onClick={() => zoomIn()} label="Zoom in">
              <path d="M17 11a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4h4z" />
            </Button>
            <Button className="mt-1" onClick={() => zoomOut()} label="Zoom out">
              <path d="M17 11a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2h10z" />
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default Zoom;
