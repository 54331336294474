import React, { memo, useEffect, useCallback, useMemo } from 'react';
import PigeonMap from 'pigeon-maps';
import Supercluster from 'supercluster';

import useWindowDimensions from 'hooks/useWindowDimensions';
import { TAB } from 'utils/constants';
import { useMapState, useProgram, useTabStore } from 'utils/store';
import theme from 'utils/theme';

import { carto as tile } from 'components/Map/tiles';
import Clusters from 'components/Map/Clusters';
import Zoom from 'components/Map/Zoom';

const MD = parseInt(theme.screens.md); // 768px
const LG = parseInt(theme.screens.lg); // 1024px
const XL = parseInt(theme.screens.xl); // 1280px

const cluster = new Supercluster({
  maxZoom: 8,
  radius: 60,
  minPoints: 4
});

const transform = b => [b?.sw[1], b?.sw[0], b?.ne[1], b?.ne[0]];

const Attribution = () => (
  <div
    dangerouslySetInnerHTML={{
      __html: tile.attribution
    }}
  />
);

const Map = ({ loading, values }) => {
  const bounds = useMapState(state => state.bounds);
  const center = useMapState(state => state.center);
  const zoom = useMapState(state => state.zoom);
  const setBounds = useMapState(state => state.setBounds);
  const setCenter = useMapState(state => state.setCenter);
  const setZoom = useMapState(state => state.setZoom);

  const tab = useTabStore(state => state.tab);

  const program = useProgram(state => state.program);

  const { width } = useWindowDimensions();

  const handleBoundsChange = useCallback(setBounds, [setBounds]);

  const handleClickCluster = useCallback(clusterId => {
    const zoomLevel = cluster?.getClusterExpansionZoom(clusterId);
    setZoom(zoomLevel);
  });

  // TODO: Should this be in a hook?
  cluster?.load(
    values?.map(data => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [data?.point[1], data?.point[0]]
      },
      data
    }))
  );

  // TODO: Is it possible or necessary to memoize this?
  const points = cluster?.getClusters(transform(bounds), Math.floor(zoom));

  // TODO: Perf test this.
  const selectedCluster = useMemo(
    () =>
      points.find(point => {
        const clusterId = point?.properties?.cluster_id;
        if (!clusterId) return;
        const leaves = cluster?.getLeaves(clusterId, Infinity);
        const match = leaves.find(leaf => leaf.data?.pid === program?.pid);
        if (match) return clusterId;
      }),
    [points]
  );

  const zoomAdjust = useCallback(() => {
    // Small devices, sidebar closed
    if (width < MD) setZoom(2 + width / 350);
    // Medium devices, sidebar opened
    else if (width < 1200) setZoom(2 + (width - 400) / 350);
    // Large devices, sidebar opened, height not growing at same rate as width
    else if (width < 1700) setZoom(2 + (width - 400) / 425);
  });

  useEffect(() => {
    if (program?.point?.length) setCenter(program?.point);
  }, [program?.point, setCenter]);

  // Set zoom level (est.) based on device width. Note growth is nonlinear.
  useEffect(() => {
    zoomAdjust();
  }, []);

  return (
    <div id="map-container" className="flex h-full relative mb-16">
      <div
        className={`absolute bottom-0 right-0 z-40 m-3 transform md:translate-y-0 ${
          tab === TAB.MAP &&
          program &&
          !loading &&
          '-translate-y-32 mr-2 mb-1 sm:mr-3 sm:mb-5 md:mb-8'
        }`}
      >
        <Zoom selected={program} onResetZoom={() => zoomAdjust()} />
      </div>

      <PigeonMap
        animate={false}
        attribution={<Attribution />}
        attributionPrefix={false}
        provider={tile.provider}
        center={center}
        zoom={zoom}
        maxZoom={18}
        onBoundsChanged={handleBoundsChange}
      >
        <Clusters
          points={points}
          selected={program}
          selectedClusterId={selectedCluster?.id}
          onClickCluster={handleClickCluster}
        />
      </PigeonMap>
    </div>
  );
};

Map.defaultProps = {
  values: []
};

export default memo(Map);
