import { bubble, colors } from 'components/colors';

import theme from 'utils/theme';

const color = (i, num) => theme.colors[colors[i]][num];

const Bubble = ({ index, show, text }) => (
  <div
    className={`absolute left-0 w-56 transform -translate-x-1/2 px-2 py-1 text-sm text-center border-2 rounded-lg ${
      !show && 'pointer-events-none'
    } ${bubble[index]}`}
  >
    {text}
    <style jsx>{`
      bottom: 17px;
      :before,
      :after {
        content: '';
        position: absolute;
        left: 45.5%;
      }
      :before {
        border-color: ${color(index, 400)} transparent;
        border-width: 11px 10px 0 10px;
        bottom: -11px;
      }
      :after {
        border-color: ${color(index, 300)} transparent;
        border-width: 12px 10px 0 10px;
        bottom: -8px;
      }
    `}</style>
  </div>
);

export default Bubble;
