import { useState, useEffect } from 'react';

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResizeOnce() {
        setWindowDimensions(getWindowDimensions());
        window.removeEventListener('resize', handleResizeOnce);
      }

      window.addEventListener('resize', handleResizeOnce);
      return () => window.removeEventListener('resize', handleResizeOnce);
    }
  }, [hasWindow]);

  return windowDimensions;
}
