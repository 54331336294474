export const google = {
  blue:
    '!4i256!2m3!1e0!2sm!3i367040414!3m14!2sen-US!3sUS!5e18!12m1!1e47!12m3!1e37!2m1!1ssmartmaps!12m4!1e26!2m2!1sstyles!2zcC5oOiMwMGFhZTZ8cC5zOi0yMCxzLnQ6M3xzLmU6Z3xwLmg6I2ZmNmUwMHxwLnY6c2ltcGxpZmllZHxwLnc6MS41LHMudDozfHMuZTpsfHAudjpvZmY!4e',
  default:
    '!2m3!1e0!2sm!3i513228056!2m70!1e2!2sspotlight!8m67!11e1!12m45!1stiles!2m2!1s103718756100308256062!2s!3m1!3s0x0%3A0xc6e158110335efb9!3m1!3s0x0%3A0x4aec5f8eff74042a!3m1!3s0x0%3A0x1953de6551212370!3m1!3s0x0%3A0xe8db8833411d13f0!3m1!3s0x0%3A0x50f093aa805e1fa4!3m1!3s0x0%3A0x613da1536626308a!3m1!3s0x0%3A0xaee069aaea14ae39!3m1!3s0x0%3A0xbc6b9cc7aa26e8b!3m1!3s0x0%3A0x5bc3808d9a61d172!3m1!3s0x0%3A0xd33574f99977ba45!3m1!3s0x0%3A0xb98ba5557b1d83e9!3m1!3s0x0%3A0x20c95ce6bcb79665!3m1!3s0x0%3A0x2c6fe4b9a17867a6!3m1!3s0x0%3A0x80696fa45b3aa8d1!3m1!3s0x0%3A0x290e7bc1a3a552dc!3m1!3s0x0%3A0xde42ff71b19f86e0!3m1!3s0x0%3A0x9f1523ff6e16844!3m1!3s0x0%3A0xe69ff5c2629f6cc6!3m1!3s0x0%3A0x6b63849290a383f4!3m1!3s0x0%3A0xc2cbf6d9eae9c070!10b1!13m11!2sa!18m5!5b0!6b0!9b1!12b1!16b0!22m3!6e2!7e3!8e2!19u12!19u14!19u29!19u37!19u30!19u70!20m1!1e6!3m8!2sen!3sus!5e1105!12m4!1e68!2m2!1sset!2sRoadmap!4e0!5m1!1e0!23i1358902',
  provider: (x, y, z, dpr) =>
    `https://www.google.com/maps/vt/pb=!1m4!1m3!1i${z}!2i${x}!3i${y}${google.blue}`,
  attribution: `Map data &copy;${new Date().getFullYear()} Google | &nbsp;<a href="https://www.google.com/intl/en-US_US/help/terms_maps.html" target="_blank" >Terms of Use</a>`
};

export const stadia = {
  provider: (x, y, z, dpr = '') =>
    `https://tiles.stadiamaps.com/tiles/alidade_smooth/${z}/${x}/${y}${dpr}.png`,
  attribution:
    '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
};

export const carto = {
  provider: (x, y, z, dpr = '') =>
    `https://d.basemaps.cartocdn.com/rastertiles/light_all/${z}/${x}/${y}${dpr}.png`,
  attribution:'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',

};

export default {
  carto,
  google,
  stadia
};
